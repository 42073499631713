/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useReducer } from "react"

import { navigate } from "gatsby"
import AppContext from "./context"
import appReducer from "./reducer"

const AppState = props => {
  const initialState = {
    cart: [],
  }

  const [state, dispatch] = useReducer(appReducer, initialState)

  const addProductToCart = product => {
    if (!state.cart.length) {
      const jsonCart = localStorage.getItem("cart")
      const localCart = JSON.parse(jsonCart)
      updateCart(localCart || [])
    }
    dispatch({
      type: "add_product_to_cart",
      payload: product,
    })
    navigate("/cart")
  }
  const updateCart = products => {
    dispatch({
      type: "update_cart",
      payload: products,
    })
  }
  const updateQuantity = (id, quantity) => {
    const newCart = state.cart
      .map(product => {
        if (product.id === id) {
          return {
            ...product,
            quantity: product.quantity + quantity,
          }
        }
        return product
      })
      .filter(product => product.quantity > 0)
    dispatch({
      type: "update_cart",
      payload: newCart,
    })
    localStorage.setItem("cart", JSON.stringify(newCart))
  }
  const removeProductFromCart = product => {
    dispatch({
      type: "remove_product_from_cart",
      payload: product,
    })
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <AppContext.Provider
      value={{
        ...state,
        addProductToCart,
        removeProductFromCart,
        updateCart,
        updateQuantity,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppState
