import { createContext } from "react"

const defaultState = {
  cart: [],
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  updateQuantity: () => {},
  updateCart: () => {},
}

const AppContext = createContext(defaultState)

export default AppContext
