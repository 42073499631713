import "./src/css/index.css"
import React from "react"
import Layout from "./src/components/Layout"

export const wrapRootElement = ({ element }) => {
  return <Layout>{element}</Layout>
}

export function Head() {
  return (
    <meta
      httpEquiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />
  )
}
