import React from "react"
import { Link } from "gatsby"

export default function Header() {
  return (
    <header className="shadow-sm">
      {/* <div className="mx-auto flex h-16 max-w-screen-xl items-center justify-between px-4">
        <div className="flex items-center gap-4">
          <Link to="/">
            <span className="sr-only">Logo</span>
            <span className="h-10 w-20 rounded-lg bg-gray-200" />
          </Link>

          <span className=" font-bold">TastyEbooks</span>
        </div>

        <nav
          aria-label="Global"
          className="hidden items-center justify-center gap-8 text-sm font-medium lg:flex lg:w-0 lg:flex-1"
        >
          <Link className="text-gray-900" to="/">
            Home
          </Link>
          <Link className="text-gray-900" to="/all-books">
            All Books
          </Link>
          <Link className="text-gray-900" to="/about">
            About
          </Link>
        </nav>

        <div className="hidden items-center gap-4 lg:flex">
          <Link
            to="/request-a-book"
            className="rounded-3xl text-white  bg-purple-600 px-5 py-2 text-sm font-medium text-white"
          >
            Request A Book
          </Link>
        </div>
      </div> */}

      <div className="mx-auto flex h-16 max-w-screen-xl items-center justify-between px-4">
        <div className="border-t border-gray-100 lg:hidden">
          <ul className="flex items-center justify-center overflow-x-auto p-4 text-sm font-medium">
            <li>
              <Link className="shrink-0 px-4 text-gray-900" to="/">
                Home
              </Link>
            </li>

            <li>
              <Link className="shrink-0 px-4 text-gray-900" to="/about">
                About
              </Link>
            </li>

            <li>
              <Link className="shrink-0 px-4 text-gray-900" to="/all-books">
                All Books
              </Link>
            </li>
          </ul>
        </div>
        <Link
          to="/request-a-book"
          className="rounded-3xl text-white  bg-purple-600 px-5 py-2 text-sm font-medium text-white"
        >
          Request A Book
        </Link>
      </div>
    </header>
  )
}
