import React from "react"
import AppState from "../contexts/app/state"
import Header from "./Header"
import Footer from "./Footer"

export default function Layout({ children }) {
  return (
    <AppState>
      <Header />
      {children}
      <Footer />
    </AppState>
  )
}
